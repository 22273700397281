import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import eliasTheaImage from '../assets/images/home/elias-thea.jpeg';


export default function HomeAbout() {
  return (
    <section className="">
      <Container>
        
        <Row className="mt-5">
          <Col md={4} className="my-auto">
            <div className="cursor-pointer">
              <Card className="card-background">
                <div className="full-background" style={{backgroundImage: `url(${eliasTheaImage})`}} loading="lazy"></div>
                <Card.Body className="pt-7 text-center" style={{minHeight: '512px'}}>
                </Card.Body>
              </Card>
            </div>
          </Col>
          <Col md={8} className=" my-auto">
            <div className="p-1 pt-3 info-horizontal d-flex">
              <div>
                
                 <h6>Professional Work and Skills:</h6>
                <p>I specialize in Ruby on Rails, Python/Django, & React and I'm expanding my proficiency in Angular. I excel in roles that require adaptability to quickly learn new technologies and thrive in both small and large team settings, utilizing tools like Agile and Jira. My strnegths are building from the ground up, but I also enjoy working on production projects, solving bugs, and creating new features.</p>

                <h6>Personal Beliefs:</h6>
                <p>I value hard work, integrity, and continuous skill-building. Your success is my success. I believe product success hinges on owning and enhancing the customer journey, not just writing code. That said, I can work with you in developing business rules & logic, not just coding.</p>

                 <h6>Conclusion:</h6>
                <p>I have a strong work ethic, dedication to improvement, and enthusiasm for learning to any team. Let's collaborate to create exceptional solutions and make a meaningful impact.</p>
                 
                
              </div>
            </div>
          </Col>
          <Col sm={12} className="my-auto">
            <div className="p-1 pt-2 info-horizontal d-flex">
              <div>
                <p>
                


                </p>
              </div>
            </div>
          </Col>
        </Row>
        <hr className="horizontal dark my-sm-4 my-3 mx-7" />
        
      </Container>
    </section>
  );
}
